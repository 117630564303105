import React from 'react';
import classNames from 'classnames';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import PropTypes from 'prop-types';

import Headshot from 'src/components/columnist/Headshot';
import ArticleList from 'src/components/links/article-list/ArticleList';
import Art from 'src/components/category/Art';

import styles from './Latest.module.scss';

const Latest = ({
  featureAvatarUrl,
  featureName,
  featureShortName,
  category,
  creators,
  bylineOverride,
  articles,
  padded,
  clickLocation,
  clickPosition,
}) => (
  <div
    className={classNames(styles.latest, { [styles.latest_padded]: padded })}
  >
    <div className={styles.latest__headshot}>
      <LazyLoadComponent>
        <Headshot
          avatarUrl={featureAvatarUrl}
          bylineOverride={bylineOverride}
          clickLocation={clickLocation}
          creators={creators}
          featureCategory={category}
          featureName={featureName}
          featureShortName={featureShortName}
          size="md"
        />
      </LazyLoadComponent>
    </div>
    <div className={styles.latest__links}>
      <ArticleList
        articles={articles}
        clickLocation={clickLocation}
        displayContext="latest"
        featureCategory={category}
        featureName={featureName}
        featureShortName={featureShortName}
        parentPosition={clickPosition}
      />
    </div>
    <div className={styles.latest__art}>
      <Art category={category} />
    </div>
  </div>
);

Latest.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      publishDate: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  bylineOverride: PropTypes.string,
  category: PropTypes.string.isRequired,
  clickLocation: PropTypes.string,
  // Note that clickPosition is only required for
  // consecutive Latest components. ie: homepage Latest.
  // This allows deep placementClick tracking when passed
  // to the ArticleList component.
  clickPosition: PropTypes.number,
  creators: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  featureAvatarUrl: PropTypes.string.isRequired,
  featureName: PropTypes.string.isRequired,
  featureShortName: PropTypes.string.isRequired,
  padded: PropTypes.bool,
};

Latest.defaultProps = {
  bylineOverride: null,
  padded: false,
  clickLocation: '',
  clickPosition: null,
};

export default Latest;
