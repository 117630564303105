import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import SectionHeader from 'src/components/global/section-headers/Dynamic';
import Card from 'src/components/card/Card';
import ArticleLink from 'src/components/links/article/Article';
import Art from 'src/components/category/Art';
import LinkButton from 'src/components/global/buttons/LinkButton';

import styles from './Highlights.module.scss';

const Highlights = ({ category, content }) => {
  const categoryLink = {
    href: '/[category]',
    as: `/${category}`,
  };

  return (
    <div className={styles.highlights}>
      <div className={styles.highlights__header}>
        <SectionHeader
          as={categoryLink.as}
          category={category}
          clickLocation={category}
          clickTitle={category}
          href={categoryLink.href}
          priority={2}
        >
          {category}
        </SectionHeader>
      </div>
      <div className={styles.highlights__card}>
        <Card
          articles={[content.spotlight.article]}
          clickLocation={category}
          clickPosition={1}
          displayContext="highlights"
          feature={content.spotlight.article.feature}
          headshotSize="sm"
        />
      </div>
      <div
        className={classNames(styles.highlights__links, {
          [styles.highlights__links_half]: content.articles.length < 3,
        })}
      >
        <div className={styles.highlights__linksContainer}>
          {content.articles.map((article, index) => (
            <div
              className={classNames(
                styles.highlights__link,
                content.articles.length > 2
                  ? styles.highlights__link_4up
                  : styles.highlights__link_2up
              )}
              key={uuidv4()}
            >
              <div className={styles.highlights__linkWrapper}>
                <ArticleLink
                  category={article.feature.category}
                  clickLocation={category}
                  // Increment to include article on card
                  clickPosition={index + 2}
                  featureName={article.feature.featureName}
                  featureShortName={article.feature.featureShortName}
                  publishDate={article.publishDate}
                  title={article.title}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      {content.articles.length < 3 ? (
        <div className={styles.highlights__art}>
          <Art category={category} />
        </div>
      ) : null}
      <div className={styles.highlights__footer}>
        <LinkButton
          as={categoryLink.as}
          category={category}
          clickLocation={category}
          clickTitle="view all"
          href={categoryLink.href}
          outline
        >
          View All
        </LinkButton>
      </div>
    </div>
  );
};

Highlights.propTypes = {
  category: PropTypes.string.isRequired,
  content: PropTypes.shape({
    articles: PropTypes.arrayOf(
      PropTypes.shape({
        feature: PropTypes.shape({
          featureName: PropTypes.string.isRequired,
          featureShortName: PropTypes.string.isRequired,
        }).isRequired,
        publishDate: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
    spotlight: PropTypes.shape({
      article: PropTypes.shape({
        feature: PropTypes.shape({
          creators: PropTypes.arrayOf(
            PropTypes.shape({
              fullName: PropTypes.string.isRequired,
            })
          ).isRequired,
          featureAvatarUrls: PropTypes.shape({
            small: PropTypes.string.isRequired,
          }).isRequired,
          featureName: PropTypes.string.isRequired,
          featureShortName: PropTypes.string.isRequired,
        }).isRequired,
        publishDate: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default Highlights;
