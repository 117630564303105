/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import classNames from 'classnames';

import { onPageNavigationEvent } from 'src/lib/analytics';

// uses Button styles to perfectly match them
import styles from './Button.module.scss';

export default function LinkButton({
  href,
  as,
  category,
  offsite,
  outline,
  clickLocation,
  clickTitle,
  children,
}) {
  const buttonVariant = outline
    ? styles[`button--outline-${category}`]
    : styles[`button--${category}`];
  if (offsite) {
    return (
      <a
        className={classNames(styles.button, buttonVariant)}
        href={href}
        rel="noopener noreferrer"
        target="_blank"
        onClick={
          clickLocation && clickTitle
            ? () => onPageNavigationEvent(clickLocation, 'link', clickTitle)
            : undefined
        }
        onKeyUp={
          clickLocation && clickTitle
            ? () => onPageNavigationEvent(clickLocation, 'link', clickTitle)
            : undefined
        }
      >
        {children}
      </a>
    );
  }
  return (
    <Link as={as} href={href}>
      <a
        className={classNames(styles.button, buttonVariant)}
        onClick={
          clickLocation && clickTitle
            ? () => onPageNavigationEvent(clickLocation, 'button', clickTitle)
            : undefined
        }
        onKeyUp={
          clickLocation && clickTitle
            ? () => onPageNavigationEvent(clickLocation, 'button', clickTitle)
            : undefined
        }
      >
        {children}
      </a>
    </Link>
  );
}

LinkButton.propTypes = {
  as: PropTypes.string,
  category: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  clickLocation: PropTypes.string,
  clickTitle: PropTypes.string,
  href: PropTypes.string.isRequired,
  offsite: PropTypes.bool,
  outline: PropTypes.bool,
};

LinkButton.defaultProps = {
  as: null,
  offsite: false,
  outline: false,
  clickLocation: '',
  clickTitle: '',
};
